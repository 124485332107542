<template>
    <div class="attribution-banner">
        <div class="logo-wrapper">
            <Anchor :url="path">
                <Image v-if="blogLogoImage" :src="blogLogoImage" class="logo" />
                <Typography v-else>{{ title }}</Typography>
            </Anchor>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    logo: {
        type: Object,
    },
    heroImage: {
        type: Object,
    },
    title: {
        type: String,
    },
    url: {
        type: String,
    },
    path: {
        type: String,
    },
});

const blogLogoImage = computed(() => {
    if (props.title === 'Diwan') {
        return '/static/media/images/diwan-english-logo-fullcolor%20copy.png';
    } else if (props.title === 'Carnegie Politika') {
        return '/static/media/images/Politika-Landing-header.png';
    } else if (props.title === 'China Financial Markets') {
        return '/static/media/images/CFM_logo-fullcolor.png';
    } else if (props.title === 'Sada') {
        return '/static/media/images/sada-logo-fullcolor.png';
    } else if (props.title === 'Strategic Europe') {
        return '/static/media/images/strategiceurope-logo-fullcolor.png';
    } else if (props.title === 'ديوان') {
        return '/static/media/images/diwan-arabic-logo-fullcolor.png';
    } else if (props.title === 'Emissary') {
        return '/static/media/images/emissary_blog_logo.png';
    } else {
        return props.logo?.url;
    }
});
</script>

<style lang="scss" scoped>
.attribution-banner {
    position: relative;
    height: 8rem;
    overflow: hidden;
    margin-top: var(--header-height);

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .logo-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .logo {
            height: 6rem;
            width: auto;
            object-fit: contain;
        }
    }
}
</style>
